export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'wexler logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/goli/logo.jpg',
        url: 'https://www.jameswexler.com/',
      },
      breakpoint: 1000,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-goli-desktop-header-logo-container',
          imageClassName: 'on-goli-desktop-header-logo',
        },
        headerClassName: 'on-goli-desktop-header',
        headerSectionsClassName: 'on-goli-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-goli-desktop-item',
        menuClassName: 'on-goli-desktop-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-goli-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'instant-cash-offer',
            content: 'Instant Cash Offer',
            menuItems: [
              {
                id: 'instant-cash-offer-one',
                url: 'https://www.jameswexler.com/guaranteed-offer/',
                content: 'Guaranteed Offer',
              },
              {
                id: 'instant-cash-offer-two',
                url: 'http://cashoffer.jameswexler.com',
                content: 'Cash Offer',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Buy Before You Sell',
            menuItems: [
              {
                id: 'sell-item-buy-before',
                url: 'https://www.hoffmanmurphy.com/home-valuation',
                content: 'Buy Before You Sell',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'http://trade-in.jameswexler.com',
                content: 'Trade In',
              },
            ],
          },
          {
            id: 'home-value',
            url: 'https://www.jameswexler.com/home-valuation/',
            content: 'See Home Value',
          },
          {
            id: 'for-sale',
            url: 'https://www.wexlerrealestate.com/',
            content: 'Homes for Sale',
          },
          {
            id: 'about-item',
            url: 'https://www.jameswexler.com/about-us/',
            content: 'About',
          },
          {
            id: 'contact-item',
            url: 'https://www.jameswexler.com/contact-us/',
            content: 'Contact',
          },
          {
            id: 'on-goli-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '480-386-0711',
            url: 'tel:480-386-0711',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-goli-mobile-header-logo-container',
          imageClassName: 'on-goli-mobile-header-logo',
        },
        type: 'mobile-default',
        menuOpener: {
          className: 'on-test',
          content: 'Open',
        },
        headerClassName: 'on-goli-mobile-header',
        headerClassNameOverride: '',
        mobileMenuClassName: 'on-goli-mobile-menu',
        menuClassName: 'on-goli-mobile-folder',
        itemClassName: 'on-goli-mobile-item',
        menuLabelActiveClassName: 'on-goli-mobile-folder-active',
        menuItemClassName: 'on-goli-mobile-item-folder',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'instant-cash-offer',
            content: 'Instant Cash Offer',
            menuItems: [
              {
                id: 'instant-cash-offer-one',
                url: 'https://www.jameswexler.com/guaranteed-offer/',
                content: 'Guaranteed Offer',
              },
              {
                id: 'instant-cash-offer-two',
                url: 'http://cashoffer.jameswexler.com',
                content: 'Cash Offer',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Buy Before You Sell',
            menuItems: [
              {
                id: 'sell-item-buy-before',
                url: 'https://www.hoffmanmurphy.com/home-valuation',
                content: 'Buy Before You Sell',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'http://trade-in.jameswexler.com',
                content: 'Trade In',
              },
            ],
          },
          {
            id: 'home-value',
            url: 'https://www.jameswexler.com/home-valuation/',
            content: 'See Home Value',
          },
          {
            id: 'for-sale',
            url: 'https://www.wexlerrealestate.com/',
            content: 'Homes for Sale',
          },
          {
            id: 'about-item',
            url: 'https://www.jameswexler.com/about-us/',
            content: 'About',
          },
          {
            id: 'contact-item',
            url: 'https://www.jameswexler.com/contact-us/',
            content: 'Contact',
          },
          {
            id: 'on-goli-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '480-386-0711',
            url: 'tel:480-386-0711',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-goli-footer-one',
      className: 'on-goli-footer-section on-goli-footer-one-section',
      sectionClassName: 'on-goli-footer-section-container d-none',
      columns: [
        {
          id: 'footer-one-column-one',
          className: 'col-md-7 on-goli-column-one',
          items: [
            {
              id: 'f-1-col-1-item-1',
              type: 'heading',
              elementType: 'p',
              data: '“Our goal is to provide world class service and an unparalleled buying and selling experience to each of our clients, while ensuring the highest level of communication in the industry.”',
            },
            {
              id: 'f-1-col-1-image',
              type: 'image',
              url: 'https://www.jameswexler.com/wp-content/uploads/2020/04/james_wexler_white_signature.png',
              className: '',
              style: { width: '299px' },
            },
            {
              id: 'f-1-col-1-item-2',
              type: 'heading',
              elementType: 'p',
              data: 'Have Questions? Call or Email Today:',
            },
            {
              id: 'f-1-col-1-item-3',
              type: 'heading',
              data: '480-386-0711',
              url: 'tel:4803860711',
              style: { color: '#a6a6a6' },
            },
            {
              id: 'f-1-col-1-item-4',
              type: 'heading',
              data: 'info@jameswexler.com',
              url: 'mailto:info@jameswexler.com',
              style: { color: '#a6a6a6' },
            },
          ],
        },
        {
          id: 'footer-one-column-one',
          className: 'col-md-4',
          items: [
            {
              id: 'f-1-col-2-image',
              type: 'image',
              className: '',
              style: { width: '200px' },
              url: 'https://si-homelight.s3.amazonaws.com/sites/goli/agent.jpg',
            },
          ],
        },
      ],
    },
    {
      id: 'on-goli-footer-two',
      className: 'on-goli-footer-section on-goli-footer-two-section',
      sectionClassName: 'on-goli-footer-section-container on-goli-footer-two-section-container',
      columns: [
        {
          id: 'footer-two-column-one',
          className: 'on-goli-footer-two-columns',
          items: [
            {
              id: 'f-2-col-1-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Important Links:',
            },
            {
              id: 'f-2-col-1-item-1',
              type: 'heading',
              data: 'DC Ranch Golf Course Homes for Sale',
              url: 'https://www.jameswexler.com/dc-ranch/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              data: 'Scottsdale Golf Homes for Sale',
              url: 'https://www.jameswexler.com/scottsdale/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-3',
              type: 'heading',
              data: 'Golf Homes for Sale in Troon Scottsdale',
              url: 'https://www.jameswexler.com/troon-north/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-4',
              type: 'heading',
              data: 'Grayhawk Golf Homes for Sale',
              url: 'https://www.jameswexler.com/grayhawk/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-5',
              type: 'heading',
              data: 'Gainey Ranch Golf Homes for Sale',
              url: 'https://www.jameswexler.com/gainey-ranch/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-6',
              type: 'heading',
              data: 'Estancia Golf Homes for Sale',
              url: 'https://www.jameswexler.com/estancia/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-7',
              type: 'heading',
              data: 'Desert Highlands Golf Homes for Sale',
              url: 'https://www.jameswexler.com/desert-highlands/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-8',
              type: 'heading',
              data: 'Silverleaf Golf Homes for Sale',
              url: 'https://www.jameswexler.com/silverleaf/golf-homes/',
            },
            {
              id: 'f-2-col-1-item-9',
              type: 'heading',
              data: 'Paradise Valley Golf Homes for Sale',
              url: 'https://www.jameswexler.com/phoenix/paradise-valley-golf-homes/',
            },
            {
              id: 'f-2-col-1-item-10',
              type: 'heading',
              data: 'Desert Mountain Golf Homes for Sale',
              url: 'https://www.jameswexler.com/desert-mountain/golf-homes/',
            },
          ],
        },
        {
          id: 'footer-two-column-two',
          className: 'on-goli-footer-two-columns',
          items: [
            {
              id: 'f-2-col-2-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Blog Posts:',
            },
            {
              id: 'f-2-col-2-item-1',
              type: 'heading',
              data: 'Desert Mountain Golf Real Estate',
              url: 'https://www.jameswexler.com/blog/desert-mountain-golf-real-estate/',
            },
            {
              id: 'f-2-col-2-item-2',
              type: 'heading',
              data: 'Desert Highlands Golf Real Estate',
              url: 'https://www.jameswexler.com/blog/desert-highlands-golf-real-estate/',
            },
            {
              id: 'f-2-col-2-item-3',
              type: 'heading',
              data: 'DC Ranch Golf Real Estate',
              url: 'https://www.jameswexler.com/blog/dc-ranch-golf-real-estate/',
            },
            {
              id: 'f-2-col-2-item-4',
              type: 'heading',
              data: 'How to Buy or Sell Home in Scottsdale While Social Distancing',
              url: 'https://www.jameswexler.com/blog/how-to-buy-or-sell-home-in-scottsdale-while-social-distancing/',
            },
            {
              id: 'f-2-col-2-item-5',
              type: 'heading',
              data: 'Estancia Golf in Scottsdale - A Course Like No Other',
              url: 'https://www.jameswexler.com/blog/estancia-golf-in-scottsdale-a-course-like-no-other/',
            },
            {
              id: 'f-2-col-2-item-6',
              type: 'heading',
              data: 'Mini Golf Phoenix AZ',
              url: 'https://www.jameswexler.com/blog/mini-golf-phoenix-az/',
            },
          ],
        },
        {
          id: 'footer-two-column-three',
          className: 'on-goli-footer-two-columns',
          items: [
            {
              id: 'f-2-col-3-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Open Hours:',
            },
            {
              id: 'f-2-col-3-heading',
              type: 'socialIcons',
              itemClassName: 'on-goli-footer-hours-items',
              className: 'on-goli-footer-hours',
              items: [
                {
                  id: 'item-monday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Monday: 7 AM - 10 PM',
                },
                {
                  id: 'item-tuesday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Tuesday: 7 AM - 10 PM',
                },
                {
                  id: 'item-wednesday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Wednesday: 7 AM - 10 PM',
                },
                {
                  id: 'item-thursday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Thursday: 7 AM - 10 PM',
                },
                {
                  id: 'item-friday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Friday: 7 AM - 10 PM',
                },
                {
                  id: 'item-saturday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Saturday: 7 AM - 10 PM',
                },
                {
                  id: 'item-sunday',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'clock',
                  data: ' Sunday: 7 AM - 10 PM',
                },
              ],
            },
          ],
        },
        {
          id: 'footer-two-column-four',
          className: 'on-goli-footer-two-columns',
          items: [
            {
              id: 'f-2-col-4-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'Contact Us:',
            },
            {
              id: 'f-2-col-4-icons',
              type: 'socialIcons',
              itemClassName: 'on-goli-footer-social-items',
              className: 'on-goli-footer-hours on-goli-footer-two-social',
              items: [
                {
                  id: 'phonebook',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'address-card',
                  url: '',
                  data: '7702 E Doubletree Ranch Rd #300 Scottsdale, AZ, 85258 United States',
                },
                {
                  id: 'phone',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'phone',
                  url: 'tel:(480)386-0711',
                  data: ' (480)386-0711',
                  style: { color: '#a6a6a6' },
                },
                {
                  id: 'mail',
                  className: '',
                  iconTypeClassName: 'fas',
                  iconClassName: 'envelope',
                  url: 'mailto:Info@JamesWexler.com',
                  data: 'info@jameswexler.com',
                  style: { color: '#a6a6a6' },
                },
              ],
            },
            {
              id: 'f-2-col-4-item-4',
              className: 'pb-2',
              type: 'heading',
              data: 'Sitemap',
              url: 'https://www.jameswexler.com/sitemap/',
            },
          ],
        },
      ],
    },
    {
      id: 'on-goli-footer-third',
      display: true,
      className: 'on-goli-footer-section-three',
      sectionClassName: 'on-goli-footer-section-container on-goli-footer-container-last',
      columns: [
        {
          id: 'f-3-col-1',
          className: 'col-lg-2 on-goli-footer-logos ps-0',
          style: {},
          items: [
            {
              id: 'f-3-col-1-item-1',
              type: 'image',
              className: 'on-goli-footer-realtor-logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/goli/logos-footer.png',
            },
          ],
        },
        {
          id: 'f-3-col-2',
          className: 'col-lg-10 on-goli-copyright-col align-left',
          style: {},
          items: [
            {
              id: 'f-3-col-2-item-1',
              type: 'heading',
              className: '',
              style: {},
              data: '*terms and conditions apply to the guaranteed offer program',
            },
            {
              id: 'f-3-col-2-item-2',
              type: 'heading',
              className: '',
              style: {},
              data: '© 2020 | James Wexler',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Wexler Realty Group',
    agentName: 'James Wexler',
    agentDescription: 'Top agents in Phoenix|Scottsdale AZ',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/goli/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/goli/hero-bg.jpg',
  },
};
